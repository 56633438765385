import React from 'react';
import Layout from '../components/layout';

class Success extends React.Component {
  render() {
    return <h1>Thanks for signing up!!</h1>;
  }
}

export default Success;
